import React, { useState, useRef, useCallback, useEffect } from "react"

import PropTypes from "prop-types"
import { connectSearchBox } from "react-instantsearch-dom"

import { Icon } from "../common/Icon"
import { IconButton } from "../common/IconButton"
import * as styles from "./SearchInput.module.scss"

const SearchInput = ({
  refine,
  delay = 400,
  focused,
  onFocus,
  onClose,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [queryText, setQueryText] = useState("")

  const timerRef = useRef(null)

  const queryHook = useCallback(
    (query, time = false) => {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(
        () => {
          refine(query)
        },
        time ? time : delay,
      )
    },
    [delay, refine],
  )

  const onChange = useCallback(
    e => {
      setQueryText(e.target.value)
      queryHook(e.target.value)
    },
    [queryHook],
  )

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      queryHook(queryText, 0)
    },
    [queryText, queryHook],
  )

  useEffect(() => {
    if (!focused) {
      // Make sure we revoke focus from the input
      inputRef.current.blur()

      // If the input is already empty dont do cleanup
      if (!inputRef.current.value.trim()) {
        return
      }

      // This timer gives a chance to the panel to disappear before
      // removing the results
      const timer = setTimeout(() => {
        inputRef.current.value = ""
        setQueryText("")
        queryHook("", 0)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [focused, queryHook])

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <input
        type="search"
        aria-label={"Search"}
        onChange={onChange}
        ref={inputRef}
        autoComplete="off"
        onFocus={onFocus}
        className={styles.input}
        placeholder="Search"
        id="search"
      />
      {!focused ? (
        <Icon name="search" />
      ) : (
        <IconButton className={styles.btn} icon="x" onClick={onClose} />
      )}
    </form>
  )
}

SearchInput.propTypes = {
  delay: PropTypes.number,
  onFocus: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  focused: PropTypes.bool,
}

const ConnectedSearchInput = connectSearchBox(SearchInput)

export { ConnectedSearchInput as SearchInput }
