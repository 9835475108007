import React, { useCallback } from "react"

import { useRCAContext } from "../../../context/RCAContext"
import { Collapsible } from "../../common/Collapsible"
import { ListItem } from "../../common/ListItem"
import { PlusIcon } from "../../common/PlusIcon"

const SchoolMenuItem = ({ school }) => {
  const { selectedMenu, setSelectedMenu } = useRCAContext()

  const onSelectMenu = useCallback(() => {
    setSelectedMenu(
      selectedMenu === school.schoolName ? false : school.schoolName,
    )
  }, [school, selectedMenu, setSelectedMenu])

  return (
    <li>
      <ListItem
        onClick={onSelectMenu}
        leftBorder={false}
        topBorder={false}
        rightBorder={false}
        size="navigation"
        subMenuLevel="Two"
        slot={
          <PlusIcon name="plus" toggle={selectedMenu === school.schoolName} />
        }
      >
        {school.schoolName}
      </ListItem>
      <Collapsible duration={250} open={selectedMenu === school.schoolName}>
        <ul className="list-reset">
          <li>
            <ListItem
              to={`/schools/${school.slug.current}`}
              leftBorder={false}
              topBorder={false}
              rightBorder={false}
              size="navigation"
              icon="chevron"
              reverse
              justifyContent="flex-end"
              subMenuLevel="Three"
            >
              School Introduction
            </ListItem>
          </li>
          {school.programmes.map(programme => (
            <li key={programme.slug.current}>
              <ListItem
                to={`/programmes/${programme.slug.current}`}
                leftBorder={false}
                topBorder={false}
                rightBorder={false}
                size="navigation"
                icon="chevron"
                reverse
                justifyContent="flex-end"
                subMenuLevel="Three"
              >
                {programme.programmeName}
              </ListItem>
            </li>
          ))}
        </ul>
      </Collapsible>
    </li>
  )
}

export { SchoolMenuItem }
