import React, { useCallback } from "react"

import classNames from "classnames"
import { Index } from "react-instantsearch-dom"

import { useRCAContext } from "../../context/RCAContext"
import { StandardGrid } from "../common/StandardGrid"
import { ResultsList } from "./ResultsList"
import * as styles from "./Search.module.scss"

const Search = ({ indices, animating, onReportRef }) => {
  const { bodyLocked, searchQuery } = useRCAContext()

  const onRef = useCallback(
    ref => {
      onReportRef(ref)
    },
    [onReportRef],
  )

  return (
    <div className={classNames(styles.outer, { [styles.locked]: bodyLocked })}>
      <StandardGrid className={classNames("searchNav", styles.nav)}>
        <div ref={onRef} className={styles.inner}>
          {searchQuery.length !== 0 &&
            indices.map(({ name, title }) => {
              return (
                <Index key={name} indexName={name}>
                  <ResultsList name={name} title={title} />
                </Index>
              )
            })}
        </div>
      </StandardGrid>
    </div>
  )
}

export { Search }
