import React, { useEffect, useRef, useCallback, useState } from "react"

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import classNames from "classnames"
import { InstantSearch } from "react-instantsearch-dom"

import { clickOutside } from "../../../utils/common"
import { addScrollListener } from "../../../utils/events"
import { searchClient, searchIndices } from "../../../utils/search"
import { ListItem } from "../../common/ListItem"
import { RotateIcon } from "../../common/RotateIcon"
import { Search } from "../../search"
import { SearchInput } from "../../search/input"
import { Menu } from "../menu/Menu"
import { MenuPanel } from "../menu/MenuPanel"
import { MENUS, useRCAContext } from "./../../../context/RCAContext"
import * as styles from "./Header.module.scss"

const Header = () => {
  // Track the header so we can see if its stuck at the top
  const headerRef = useRef(null)
  const searchRef = useRef(null)
  const searchInputWrapperRef = useRef(null)
  const menuRef = useRef(null)

  const {
    isNavOpen,
    isSearchOpen,
    toggleMenuOpen,
    setBodyLocked,
    setSearchQuery,
    closeMenu,
    currentMenuRef,
  } = useRCAContext()

  // const scrollToHeader = () => {
  //   if (headerRef.current) {
  //     headerRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     })
  //   }
  // }

  const headerToggle = (toggle) => {
    // timeout to set isNavOpen at a delayed time
    // prevents the menu instantly closing as its opened
    setTimeout(() => {
      toggleMenuOpen(toggle)
    }, 100)
    // toggleMenuOpen(toggle)
  }

  // disable body scroll
  // uncomment to use
  useEffect(() => {
    let locked = true

    if (isNavOpen) {
      disableBodyScroll(menuRef.current, { reserveScrollBarGap: true })
    } else if (isSearchOpen) {
      disableBodyScroll(searchRef.current, { reserveScrollBarGap: true })
    } else {
      locked = false
      clearAllBodyScrollLocks()
    }

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setBodyLocked(locked)
      })
    })
  }, [isNavOpen, isSearchOpen, setBodyLocked])

  const onCloseSearch = useCallback(() => {
    if (currentMenuRef.current !== MENUS.NAV) {
      closeMenu()
    }
    setSearchQuery(``)
  }, [closeMenu, setSearchQuery, currentMenuRef])

  useEffect(() => {
    if (isSearchOpen && searchRef.current) {
      return clickOutside(searchRef.current, onCloseSearch, (el, evt) => {
        return (
          evt.target !== el &&
          !el.contains(evt.target) &&
          evt.target !== searchInputWrapperRef.current &&
          !searchInputWrapperRef.current.contains(evt.target)
        )
      })
    }
  }, [isSearchOpen, onCloseSearch])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={searchIndices[0].name}
      onSearchStateChange={({ query }) => setSearchQuery(query)}
    >
      <header
        ref={headerRef}
        className={classNames(styles.header, {
          [styles.navOpen]: isNavOpen,
          [styles.searchOpen]: isSearchOpen,
        })}
      >
        {/* <Box
          sx={{
            zIndex: 100,
            position: "relative",
          }}
        > */}
        <nav className={styles.nav}>
          <div className={classNames(styles.option, styles.optionMenu)}>
            <ListItem
              onClick={() => headerToggle(MENUS.NAV)}
              topBorder={false}
              rightBorder={true}
              leftBorder={false}
              size="navigation"
              slot={<RotateIcon name="plus" toggle={isNavOpen} />}
              to="/"
            >
              Menu
            </ListItem>
          </div>

          <div
            ref={searchInputWrapperRef}
            className={classNames(styles.option, styles.optionSearch)}
          >
            <SearchInput
              onFocus={() => headerToggle(MENUS.SEARCH)}
              onClose={closeMenu}
              focused={isSearchOpen}
            />
            {/* <ListItem
              onClick={() => headerToggle(MENUS.SEARCH)}
              topBorder={false}
              rightBorder={false}
              leftBorder={false}
              size="navigation"
              slot={
                isSearchOpen ? (
                  <Icon name="plus" sx={{ transform: `rotate(45deg)` }} />
                ) : (
                  <Icon name="search" />
                )
              }
              noHover={isSearchOpen}
              invert={isSearchOpen}
            >
              Search
            </ListItem> */}
          </div>
        </nav>
        {/* </Box> */}

        <MenuPanel keyName="menu" open={isNavOpen}>
          {animating => (
            <Menu
              animating={animating}
              onReportRef={ref => (menuRef.current = ref)}
            />
          )}
        </MenuPanel>

        <MenuPanel keyName="menu" open={isSearchOpen}>
          {animating => (
            <Search
              indices={searchIndices}
              animating={animating}
              onReportRef={ref => (searchRef.current = ref)}
            />
          )}
        </MenuPanel>
      </header>
    </InstantSearch>
  )
}
export { Header }
