import React from "react" // eslint-disable-line no-unused-vars

import classNames from "classnames"
import PropTypes from "prop-types"

import { ReactComponent as ArrowLeftIcon } from "../../../images/icons/arrow-left.svg"
import { ReactComponent as ArrowRightIcon } from "../../../images/icons/arrow-right.svg"
import { ReactComponent as ChevronDownIcon } from "../../../images/icons/chevron-down.svg"
import { ReactComponent as ChevronIcon } from "../../../images/icons/chevron.svg"
import { ReactComponent as LinkIcon } from "../../../images/icons/link.svg"
import { ReactComponent as LinkArrowIcon } from "../../../images/icons/link-arrow.svg"
import { ReactComponent as MinusIcon } from "../../../images/icons/minus.svg"
import { ReactComponent as PlusIcon } from "../../../images/icons/plus.svg"
import { ReactComponent as SearchIcon } from "../../../images/icons/search.svg"
import { ReactComponent as SelectArrowIcon } from "../../../images/icons/select-arrow.svg"
import { ReactComponent as SmallPlusIcon } from "../../../images/icons/small-plus.svg"
import { ReactComponent as TickIcon } from "../../../images/icons/tick.svg"
import { ReactComponent as XIcon } from "../../../images/icons/x.svg"
import { ReactComponent as CircleArrow } from "../../../images/icons/circle-arrow.svg"
import { ReactComponent as LinkArrow } from "../../../images/icons/link-arrow.svg"

import * as styles from "./Icon.module.scss"

const iconMap = {
  search: SearchIcon,
  plus: PlusIcon,
  "small-plus": SmallPlusIcon,
  minus: MinusIcon,
  chevron: ChevronIcon,
  "chevron-down": ChevronDownIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-right": ArrowRightIcon,
  tick: TickIcon,
  x: XIcon,
  link: LinkIcon,
  "link-arrow": LinkArrowIcon,
  "select-arrow": SelectArrowIcon,
  "circle-arrow": CircleArrow,
  "link-arrow": LinkArrow,
}

export const iconKeys = Object.keys(iconMap)

const Icon = ({ name, size = "normal", className, ...props }) => {
  if (!iconKeys.includes(name)) {
    return null
  }

  const IconName = iconMap[name]

  return (
    <span
      className={classNames(styles.icon, [styles[size]], className)}
      {...props}
    >
      <IconName />
    </span>
  )
}

Icon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  size: PropTypes.oneOf([
    "xsmall",
    "small",
    "normal",
    "medium",
    "large",
    "free",
  ]),
}

export { Icon }
