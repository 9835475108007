import React, { useMemo } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import { Icon, iconKeys } from "../Icon"
import { InternalExternalLink } from "../InternalExternalLink"
import * as styles from "./Tag.module.scss"

// Remaps tag button to corresponding icon size
const iconSizes = {
  normal: "xsmall",
}

const Tag = ({
  children,
  icon,
  reverse,
  size = "normal",
  onClick,
  to,
  label,
  invert,
  squared,
  blink,
  className,
  ...props
}) => {
  const cls = classNames(
    styles.tag,
    [styles[size]],
    {
      blink: blink,
      [styles.reverse]: reverse,
      [styles.invert]: invert,
      // [styles.squared]: squared,
    },
    className,
  )

  const tagType = useMemo(() => {
    if (to) {
      return "link"
    }

    if (onClick) {
      return "button"
    }

    return "normal"
  }, [onClick, to])

  const innerContent = useMemo(() => {
    return (
      <>
        {icon && (
          <Icon className={styles.icon} size={iconSizes[size]} name={icon} />
        )}
        {children}
      </>
    )
  }, [icon, children, size])

  if (tagType === "button") {
    return (
      <button onClick={onClick} className={cls} {...props}>
        {innerContent}
      </button>
    )
  }

  if (tagType === "link") {
    return (
      <InternalExternalLink
        aria-label={label}
        to={to.trim()}
        className={cls}
        {...props}
      >
        {innerContent}
      </InternalExternalLink>
    )
  }

  return (
    <span className={cls} {...props}>
      {innerContent}
    </span>
  )
}

Tag.propTypes = {
  // Text to display in the tag
  children: PropTypes.string,

  // Icon to display on the tag
  icon: PropTypes.oneOf(iconKeys),

  // Whether the icon should be reversed or not
  reverse: PropTypes.bool,

  // The size of the tag
  size: PropTypes.oneOf(["small", "normal"]),

  // Invert the tags color
  invert: PropTypes.bool,

  // Whether to square off the edges
  squared: PropTypes.bool,

  // Shouldnt be here really but oh well, a blink animation
  blink: PropTypes.bool,
}

export { Tag }
