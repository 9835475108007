import React from "react"

import classNames from "classnames"

import { Button } from "../Button"
import { Icon } from "../Icon"
import * as styles from "./IconButton.module.scss"

const IconButton = ({
  icon,
  className,
  iconProps = {},
  sx = {},
  onClick,
  children,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      className={classNames(styles.btn, className)}
      {...props}
    >
      {children}
      <Icon name={icon} {...iconProps} />
    </Button>
  )
}

export { IconButton }
