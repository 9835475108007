import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import { Icon } from "../Icon"
import * as styles from "./PlusIcon.module.scss"

const PlusIcon = ({
  startScale = 1,
  scale = 0,
  iconProps = {},
  toggle = false,
  className,
}) => {
  return (
    <span
      className={classNames(
        styles.wrapper,
        { [styles.toggled]: toggle },
        className,
      )}
      style={{ "--scale-total": scale, "--scale-start": startScale }}
    >
      <Icon name="plus" {...iconProps} />
    </span>
  )
}

PlusIcon.propTypes = {
  iconProps: PropTypes.object,
  scale: PropTypes.number,
  startScale: PropTypes.number,
  toggle: PropTypes.bool,
  timing: PropTypes.number,
  sx: PropTypes.object,
}

export { PlusIcon }
