import React, { useMemo } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import { iconKeys, Icon } from "../Icon"
import { InternalExternalLink } from "../InternalExternalLink"
import * as styles from "./ListItem.module.scss"

const iconSize = {
  normal: "normal",
  large: "large",
}

const justifyCls = {
  "flex-end": styles.justifyEnd,
}

const ListItem = ({
  reverse,
  children,
  icon,
  to,
  onClick,
  leftBorder = true,
  rightBorder = true,
  topBorder = true,
  bottomBorder = true,
  size = "normal",
  tagName: El = "p",
  slot = null,
  subMenuLevel,
  invert = false,
  noHover = false,
  justifyContent,
  className,
  ...props
}) => {
  const cls = classNames(
    styles.item,
    [styles[size]],
    [styles[`subMenu${subMenuLevel}`]],
    [justifyCls[justifyContent]],
    {
      [styles.invert]: invert,
      [styles.reverse]: reverse,
      [styles.topBorder]: topBorder,
      [styles.rightBorder]: rightBorder,
      [styles.bottomBorder]: bottomBorder,
      [styles.leftBorder]: leftBorder,
      "no-hover": noHover,
    },
    className,
  )

  const itemType = useMemo(() => {
    if (onClick) {
      return "button"
    }

    if (to) {
      return "link"
    }

    return "normal"
  }, [to, onClick])

  const innerContent = useMemo(
    () => (
      <>
        <span className={styles.content}>{children}</span>
        {(icon || slot) && (
          <span className={styles.iconWrapper}>
            {slot}

            {icon && (
              <Icon className={styles.icon} name={icon} size={iconSize[size]} />
            )}
          </span>
        )}
      </>
    ),
    [icon, children, size, slot],
  )

  if (itemType === "button") {
    return (
      <button className={cls} onClick={onClick} {...props}>
        {innerContent}
      </button>
    )
  }

  if (itemType === "link") {
    return (
      <InternalExternalLink className={cls} to={to}>
        {innerContent}
      </InternalExternalLink>
    )
  }

  return (
    <El className={cls} {...props}>
      {innerContent}
    </El>
  )
}

ListItem.propTypes = {
  // The size to display
  size: PropTypes.oneOf(["normal", "large", "navigation"]),

  // Whether the flex order should be reversed
  reverse: PropTypes.bool,

  // Whether the contents should be spaced
  justifyContent: PropTypes.string,

  // The main content
  children: PropTypes.node,

  // An icon to attach
  icon: PropTypes.oneOf(iconKeys),

  // A destination for a link
  to: PropTypes.string,

  // A callback for a button
  onClick: PropTypes.func,

  // Whether to attach a top border
  topBorder: PropTypes.bool,

  // Whether to attach a right border
  rightBorder: PropTypes.bool,

  // Whether to attach a left border
  leftBorder: PropTypes.bool,

  // Whether to attach a bottom border
  bottomBorder: PropTypes.bool,

  // The tag to render the children string in
  tagName: PropTypes.string,

  // An additional slot to add any content
  slot: PropTypes.node,

  subMenuLevel: PropTypes.string,

  invert: PropTypes.bool,
}

export { ListItem }
