import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import { Icon, iconKeys } from "../Icon"
import * as styles from "./RotateIcon.module.scss"

const RotateIcon = ({
  name,
  rotate = 45,
  startRotate = 0,
  iconProps = {},
  toggle = false,
}) => {
  return (
    <span
      className={classNames(styles.wrapper, { [styles.toggled]: toggle })}
      style={{ "--rotate-total": rotate, "--rotate-start": startRotate }}
    >
      <Icon name={name} {...iconProps} />
    </span>
  )
}

RotateIcon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  iconProps: PropTypes.object,
  rotate: PropTypes.number,
  startRotate: PropTypes.number,
  toggle: PropTypes.bool,
}

export { RotateIcon }
