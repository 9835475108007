// animation

export const animation = {
  page: {
    duration: 0.035,
  },
  menu: {
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
}
